import { Form, Formik } from 'formik';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSystemContext } from '../Context/SystemContext';
import { GroupInput, Input } from '../components/Input';
import '../styles.css';

import fundoDesvios from '../assets/background_desvios.png';
import logoDesvios from '../assets/logo_desvios.png';

const yup = require('../interface/yup-br');

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

const recoverySchema = yup.object().shape({
    email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
});

export default function Login() {
    const { login, apiPost, methods } = useSystemContext();
    const [message, setMessage] = useState('');
    const [recoveryMessage, setRecoveryMessage] = useState('');
    const [recoveryToken, setRecoveryToken] = useState(false);

    async function handleLogin(values) {
        let response = await login(values);

        if (response.status === 200) {
            window.location.href = '/';
        } else {
            console.log('@@@@', response);
            setMessage('Usuário ou senha inválidos');
            localStorage.removeItem('token');
        }
    }

    async function handleRecoveryToken(values) {
        try {
            let response = await apiPost('/crew-recovery-token', { email: values.email });
            if (response.status === 200) {
                setRecoveryMessage('');
                methods.notificationSuccess('E-mail enviado com sucesso.');
            } else {
                setRecoveryMessage(response.data?.message || 'Erro ao enviar token');
            }
        } catch (error) {
            setRecoveryMessage(error.response?.data?.message || 'Erro ao enviar token');
        }
    }

    return (
        <section className="flex items-center justify-center min-h-screen bg-gray-100" style={{ backgroundImage: `url(${fundoDesvios})`, backgroundSize: 'cover' }}>
            <div className="p-10 mx-auto text-white w-96">
                <div className="py-2 text-center">
                    <img src={logoDesvios} alt="logo" className="object-contain" />
                </div>
                <div className="py-2 text-2xl text-center">
                    {recoveryToken ? 'Recuperar Token' : 'Login'}
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={recoveryToken ? recoverySchema : (isMobile ? null : schema)}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            if (recoveryToken) {
                                handleRecoveryToken(values);
                            } else {
                                handleLogin(values);
                            }
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    <Form>
                        <div className='caret-black'>
                            {isMobile ?
                                <GroupInput>
                                    {recoveryToken ?
                                        <Input name="email" type="email" label="Email" className="text-black" labelClassName="text-white" />
                                        :
                                        <Input name="token" type="text" label="Token" className="text-black" labelClassName="text-white" />
                                    }
                                </GroupInput>
                                :
                                <div>
                                    <GroupInput>
                                        <Input name="email" type="email" label="Email" className="text-black" labelClassName="text-white" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="password" type="password" label="Senha" className="text-black" labelClassName="text-white" />
                                    </GroupInput>
                                </div>
                            }
                            <div className="w-full py-2 mt-4 text-center text-gray-800">
                                {recoveryToken ?
                                    <button className="w-3/4 py-2 border rounded shadow-md bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover">Enviar por e-mail</button>
                                    :
                                    <button type="submit" className="w-3/4 py-2 border rounded shadow-md bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover">Acessar sistema</button>
                                }
                            </div>
                            <div className="w-full text-center text-red-500">{recoveryToken ? recoveryMessage : message}</div>
                            <div className="fixed left-0 w-full text-center bottom-7">
                                <button onClick={() => setRecoveryToken(!recoveryToken)} className="text-sm text-white no-underline">{recoveryToken ? 'Voltar ao Login' : 'Recuperar token por e-mail'}</button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </section>
    );
}
